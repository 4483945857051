/* Tablet */
@media only screen and (max-width: 768px) {
    .ObjectCard {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .cardImg img {
        width: 30vw;
        margin-bottom: 20px; 
        margin-right: 0px;

    }
    
    .cardText__paragraph {
        font-size: 12px;
    }

    .cardText__title {
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: 700;
    }
    
    .cardText__paragraph {
        font-size: 12px;
        margin-bottom: 10px;
        font-weight: 300;
    }
    
    .CarrouselsDiv {
        margin-bottom: 10px;
    }

    .cardCall {
        display: flex;
        flex-shrink: 0;
        border-radius: 32px;
        background: #3561FF;
        margin-top: 100px;
        padding: 80px;
    }
    
    .elevateText {
        width: 100%;
    }
    
    .elevateText h2 {
        text-align: center;
        color: #FFF;
        font-size: 30px;
        font-weight: 400;
        line-height: 50px; 
        letter-spacing: -1px;
        text-transform: capitalize;
    }
    
    .buttonCall p {
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 200;  
        margin-bottom: 25px;
        margin-top: 40px;
    }
    
    .buttonCall {
        width: 100%;
    }
    
    .buttonCarrusel__Button {
        font-size: 22px;
        font-style: normal;
        font-weight: lighter;
        padding: 10px;
        border-radius: 30px;
        background-color: transparent;
        color: var(--color-primary);
        border: 1px solid var(--color-primary);
        float: none;
        margin-bottom: 20px;
        width: 70%;
        margin-left: 15%;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
     
    .CarrouselsDiv > h2 {
        margin-bottom: 30px;
    }

    .elevateText h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 22px; 
    }

    .cardCall {
        margin-top: 50px;
        padding: 40px;
    }

    .buttonCall p {
        color: #FFF;
        font-size: 10px;
        font-style: normal;
        font-weight: 200;  
        margin-bottom: 0px;
        margin-top: 0px;
        text-align: center;
    }

    .elevateText {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


    /* Cellphone */
    @media only screen and (max-width: 480px) {

        .reference{
            margin-bottom: 100px;
            margin-top: 100px;
        }

        .cardText{
            margin-bottom: 50px;
            margin-top: 50px;
        }

        .cardText h2{
            margin-bottom: 10px;
        }

        .cardText h3{
            margin-bottom: 30px;
        }

        .nextButton{
            color: rgba(0, 0, 0, 0.80);
            font-size: 18px;
            font-style: normal;
            font-weight: lighter;
            padding: 10px;
            border-radius: 30px;
            background-color:var(--color-primary);
            color: white;
            border: none;
            float: none;
            margin-bottom: 20px;
        }

        .nextButton:active{
            color: rgba(0, 0, 0, 0.80);
            font-size: 18px;
            font-style: normal;
            font-weight: lighter;
            padding: 10px;
            border-radius: 30px;
            background-color:var(--color-primary);
            color: white;
            border: none;
            float: none;
            margin-bottom: 20px;
        }

     

        .cardCall p{
            display: none;
        }

        .buttonCall{
            display: none;
        }

        .cardImg img {
            width: 50vw;
            margin-bottom: 20px; 
            margin-right: 0px;

        }
    }

