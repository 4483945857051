
  /* Tablet */
  @media only screen and (max-width: 768px) {
    .elementsCards{
      margin-top: 100px;
    }
  
    .card-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      
      .customCard {
        width: calc(100% - 10px); 
        margin-bottom: 20px;
        display: flex; 
        align-items: center; 
      }
      
      .imgCard {
        width: 15%; 
      }
      
      .imgCard img {
        margin-top: 40px;
        width: 100%; 
        height: auto; 
        border-top-left-radius: 8px; 
        border-bottom-left-radius: 8px; 
      }
      
      .cardbody {
        flex: 1; 
        padding: 20px; 
        padding-bottom: 0px;
        padding-top: 10px;
      }
      
      .cardTitle {
        font-size: 20px; 
        margin-bottom: 10px;
        font-weight: 400; 
        text-align: center;
      }
      
      .cardText {
        font-size: 14px; 
        text-align: center;
      }
  }
  
  /* Cellphone */
  @media only screen and (max-width: 480px) {
  
  .generalContainer{
    margin-top: 170px;
  }
  .reference{
    padding-top: 50px;
  }
  
  #cardText{
    font-size: 11px;
    text-align: center;
  }
  
  #cardTitle{
    text-align: center;
    font-weight: medium;
    color: #3461FF;
  }
  
  .cardbody{
    margin-bottom: 30px;
  }
  
  
  .imgCard{
    display: none;
  }
  
  }