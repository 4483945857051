.containerMenuElements {
  position: fixed;
  width: 100%; 
  background-color: #ffffff; 
  padding-bottom: 10px;
  border-bottom: 0.5px solid var(--color-primary);
  z-index: 998;
  top: 0; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menuElements {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; 
  width: 75%;
  margin: auto;
  padding-top: 10px;
}

.scrollElements {
  cursor: pointer;
}

.menuElements h1 {
  color: var(--color-primary);
  cursor: pointer;
}

.menuElements a {
  margin-right: 1.5rem;
  font-size: 1.2vw;
}

.menuElements a:hover {
  text-decoration: none; 
  color: var(--color-primary);
  font-weight: bold; 
  font-size: 16px;
}


.scrollElements {
  display: flex;
  align-items: center;
}


.hamburger-icon {
  display: none; 
  cursor: pointer;
}

.line {
  width: 25px;
  height: 4px;
  background-color: #333;
  margin: 6px 0;
}




