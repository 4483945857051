

:root {
    --color-primary: #3461FF;
    --color-secondary: #000000;
    --color-tertiary: #FFFFFF;
    --color-background: #FAFAFA;
  }
  

*{
font-family: "Roboto", sans-serif;
text-decoration: none;
list-style: none;
box-sizing: border-box;
padding: 0px;
margin: 0px;
}