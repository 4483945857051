.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 2px solid #3461FF;
    border-radius: 15px;
    z-index: 1000;
    animation-name: smallBig; 
    animation-duration: 1.5s; 
    animation-timing-function: ease; 
    animation-iteration-count: 1;
  }
  
  .popup__content {
    text-align: center;
    display: flex;
    width: 600px;
  }

  .popup__imgAndButton{
    width: 50%;
    position: relative;
      }


  .popup__image{
width: 100%;
height: 100%;
border-radius: 13px 0 0 13px; 
  }

  .popup__button {
    position: absolute; 
    top: 10px; 
    left: 10px; 
    background-color:white;
    color: #3461FF;
    padding: 5px;
    border: none;
    border-radius: 5px;
    box-shadow: 5px 5px 10px black;
  }



  .popup__button:hover{
    background-color: #3461FF;
    color: white;
  }

  .popup__button:active{
    background-color: black;
    color: white;
  }

  .inputPopUp {
    width: 50%;
    margin: auto;
    padding: 20px;
      }

  .inputPopUp >label >input {
 display: inline-block;
 margin-bottom: 5px;
 border-radius: 10px;
 border: #3461FF 1px solid;
 height: 30px;
  }

  .inputPopUp input::placeholder {
    padding-left: 10px;
    font-size: 10px; 
  }

  .inputPopUp__button {
width: 50%;
margin: auto;
display: flex;
padding: 13px 24px;
align-items: center;
justify-content: center;
gap: 10px;
border-radius: 70px;
background: #3461FF;
border: none;
margin-top: 10px;
color: white;
      }

.inputPopUp__button:hover {
border: solid 1px #3461FF;
background-color: white;
color: #3461FF; 
}

  .inputPopUp__title{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .inputPopUp__paragraph{
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 14px;
  }

  .BookCall{
    border-radius: 70PX;
    background-color: var(--color-primary);
    color: white;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    border: none;
    font-size: 16px;
    font-style: normal;
  
    }

    .BookCall:hover{
      border-radius: 70PX;
      background-color: var(--color-secundary);
      color: var(--color-primary);
      border: 1px solid var(--color-primary);
      }


      .modalBackdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); 
        z-index: 999; 
      }
        

    
      @keyframes smallBig {
        0% {
          transform: scale(0.5) translate(-50%, -50%); 
        }
        100% {
          transform: scale(1) translate(-50%, -50%); 
        }
      }







