.reference{
    width: 75%;
    margin: auto;
}

.ObjectCard{
    display: flex;
    align-items: center;
}

.cardImg img {
    width: 15vw; 
    margin-right: 50px;
}

.cardText__title {
    font-size: 30px;
    margin-bottom: 10px;
    font-weight: 700;
}

.cardText__subtitle {
    font-size: 1rem;
    margin-bottom: 10px;
    font-weight: 300;
}

.buttonCarrusel__Button{
    color: rgba(0, 0, 0, 0.80);
    font-size: 16px;
    font-style: normal;
    font-weight: bolder;
    padding: 10px;
    border-radius: 30px;
    background-color: var(--color-primary);
    color: white;
    border: none;
    margin-right: 5px;
    float: inline-end;
}

.buttonCarrusel__Button:hover{
    background-color: white;
    color: #3561FF;
    border: 1px solid #3561FF;
}

.buttonCarrusel__Button:active{
    background-color: white;
    color: black;
    border: 1px solid black;
}

.CarrouselsDiv__title{
    font-size: 50px;
    font-weight: 550;
    margin-bottom: 70px;
    margin-top: 100px;
}

.cardCall{
    display: flex;
    flex-shrink: 0;
    border-radius: 32px;
    background: #3561FF;
    margin-top: 100px;
    padding: 80px;
}

.elevateText{
    width: 50%;
}

.elevateText h2{
    text-align: center;
    color: #FFF;
    font-size: 40px;
    font-weight: 400;
    line-height: 50px; 
    letter-spacing: -1px;
    text-transform: capitalize;
}

.buttonCall p{
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 200;  
    margin-bottom: 25px;
    margin-top: 40px;
}

.buttonCall{
    width: 50%;
}



