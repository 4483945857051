

/* Tablet */
@media only screen and (max-width: 768px) {
  .MainBothElements {
    display: flex;
    flex-direction: column;
    padding-top: 0rem;
    height: 100vh; 
    margin-top: 0; 
    padding-top: 60px;

  }

  .HelpTo {
    width: 100%;
    margin-top: 2.5rem; 
    margin-left: 0; 
  }


  .HelpTo__text {
 text-align: center;
 margin-bottom: 2rem;
 font-size: 2.5rem;
  }

  .textChange {
    font-size: 50px; 
    text-align: center;
    margin-top: 10px;
  }

  .imagesElements {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
  }

  .imagesElements__laptop {
display: none;
  }
  .imagesElements__celular {
    width: 40%;
    margin-top: 60px;
  }

}

/* cell */

@media only screen and (max-width: 430px) {
  .MainBothElements {
    display: flex;
    flex-direction: column;
    padding-top: 0rem;
    margin-top: 0; 
  }

  .HelpTo {
    width: 100%;
    margin-top: 2.5rem; 
    margin-left: 0; 
    padding-top: 30px;
  }

  .textChange {
    font-size: 50px; 
    text-align: center;
    margin-top: 10px;
  }

  .imagesElements__laptop {
   display: none;
  }

  .imagesElements__celular {
    width: 60vw;
  }

}
