
  /* Tablet */
  @media only screen and (max-width: 768px) {
    .PhaderCounter{
      margin-bottom: 5px;
    }

    .PhaderCounter__text{
      text-align: center;
    }

    .counterDiv{
      width: 100%;  
    }
    .counterDiv__paragraph{
      text-align: center;  
      font-size: 20px;
      margin-top: 7px;
    }
    .allCounters{
      display: flex;
      flex-direction: column;
    }
    .counterElements{
      text-align: center;
      font-size: 55px;
    }
    .counterText h2{
      margin-top: 95px; 
      font-size: 30px;
      font-weight: normal;
      text-align: center;
    }
  
  }
