
/* Tablet */

@media only screen and (max-width: 768px) {
    .hamburger-icon {
      display: block; 
    }
  
    .menu-items {
      display: none; 
      flex-direction: column; 
      background-color: #fff; 
      position: absolute; 
      top: 60px; 
      left: 0;
      right: 0;
      padding: 20px;
    }
  
    
    .scrollElements.open .menu-items {
      display: flex;
  
    }
  
    .buttonComeBack{
      font-size: 20px;
    }
  
    .menuElements a {
      margin-right: 15px;
      font-size: 20px;
      margin-top: 23px;
    }
    
    .menuElements a:active {
      color: black;
      font-weight: bold; 
      font-size: 25px;
    }
    
  }
  
  