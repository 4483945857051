
 /* General elements*/

 .MainBothElements {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(135deg, #1ba977, #3461FF);
  background-size: 200% 200%;
  animation: cambio-color 5s infinite;
}

@keyframes cambio-color {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}



 /* text*/

.HelpTo{
width: 35%;
margin-top: 0px;
margin-left:12% ;
}

.HelpTo__text{
font-weight: 400;
font-size: 5vw;
color:bisque;
font-weight: bold;
}


.textChange{
color:var(--color-primary);
font-size: 3vw;
font-weight: 700;
margin-top: 2rem;
font-weight: 500;
height: 30px;
color: var(--color-background, #FAFAFA);
}

 /*images*/

.imagesElements{
  display: flex;
  flex-wrap: wrap; 
  align-items: center;
}


.imagesElements__laptop {
  width: 35vw;
  margin-left: 50px;
  position: absolute;
  animation: slideInFromLeft 2s ease-out forwards;
  z-index: 30;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}



.imagesElements__celular {
  width: 13vw;
  position: relative;
  z-index: 31;
  animation: slideInFromLeftCelular 2s ease-out forwards;
}

@keyframes slideInFromLeftCelular {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}






